<template lang="pug">
  div(@click="$emit('click', item)").search-item
    div.search-item__cell
      .search-item__where(
        :style="{backgroundColor: label.color}"
      ) {{ label.text }}
    div.search-item__cell {{ item.application_placed_at| formattedDate }}
      | /
      span(v-if="item.application_paid_at" data-test="live-search-paid") {{ item.application_paid_at | formattedDate}}
      span(v-else data-test="live-search-not-paid") -
    div.search-item__cell {{ item.number }}
    div.search-item__cell {{ item.is_crm ? item.name : `${item.first_name} ${item.last_name}` }}
    div.search-item__cell {{ item.date_of_birth | formattedDate }}
    div.search-item__cell {{ item.email }}
    div.search-item__cell {{ item.phone }}
    div.search-item__cell {{ item.application_id }}

</template>

<script>
export default {
  props: {
    item: Object
  },

  computed: {
    label() {
      let color = this.item.is_crm ? 'rgb(56, 142, 50)' : 'rgb(63, 31, 212)'
      let text = this.item.is_crm ? 'CRM' : 'Task'
      return {
        color,
        text
      }
    },
  },

  methods: {
    go() {
      let routeName = this.getItemRouteName()
      this.$roputer.push({
        name: routeName,
        query: {
          search: this.item.number,
          page: 1
        }
      })
    },

    getItemRouteName() {
      if (this.item.is_crm) return this.$ROUTER_NAMES.CANDIDATES_ALL
      else return this.$ROUTER_NAMES.TASK_APPLICATIONS
    }
  }
}
</script>

<style lang="scss">
.search-item {
  height: 40px;
  font-size: 12px;
  align-items: center;
  display: table-row;
  cursor: pointer;

  &:hover {
    background-color: rgba($primary-color, .3);
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding-right: 5px;

    &:first-child {
      padding-left: 16px;
    }
  }

  &__where {
    width: 80px;
    color: #fff;
    text-align: center;
  }
}
</style>
